<template>
  <div>
    <vca-column>
      <vca-card class="shadowed"> </vca-card>
    </vca-column>

    <button class="vca-button" @click="add()">{{ $t("button.save") }}</button>
  </div>
</template>

<script>
export default {
  name: "ExpenseAdd",
  methods: {
    add() {
      this.$store.dispatch({ type: "expenses/create" });
    },
  },
  computed: {
    create: {
      set(value) {
        this.$store.commit("expenses/create", value);
      },
      get() {
        return this.$store.state.expenses.create;
      },
    },
  },
};
</script>
