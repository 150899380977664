<template>
  <vca-card>
    <h2>{{ $t("expense.add.header") }}</h2>
    <expenseAdd />
  </vca-card>
</template>

<script>
import ExpenseAdd from "@/components/finance/expenses/ExpenseAdd";
export default {
  name: "ExpensesAdd",
  components: { ExpenseAdd },
  created() {
    this.$store.dispatch({ type: "expenses/list" });
  },
};
</script>
